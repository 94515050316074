import { routeConstants } from "../constants";
import { RootContext } from "../utils/utils";

/**
 * Gets route.
 * @param {String} routeName
 * @param {String} language
 * @param {Object} qs
 */
const getRoute = (routeName, language = "pl", qs = {}) => {
  const hasQueryString = typeof qs === "object" && Object.keys(qs).length > 0;
  const queryString = Object.keys(qs)
    .map((key) => `${key}=${qs[key]}`)
    .join("&");

  if (!routeName || routeName === RootContext) {
    return language === "pl" ? RootContext : `/${language}`;
  }

  const specificRoute =
    language === "pl" ? routeConstants[routeName] : routeConstants["_EN"][routeName];

  const routePrefix = language === "pl" ? "" : `/${language}`;

  return `${routePrefix}${specificRoute}${hasQueryString ? `?${queryString}` : ""}`;
};

const getRouteAfterLangChange = (path, prevLanguage, newLanguage) => {
  if (path === RootContext || path === `/${prevLanguage}`) {
    return `/${newLanguage}`;
  }

  const prevLangRoutes =
    prevLanguage === "pl"
      ? Object.fromEntries(Object.entries(routeConstants).filter(([key]) => key !== "_EN"))
      : routeConstants["_EN"];

  const newLangRoutes =
    newLanguage === "pl"
      ? Object.fromEntries(Object.entries(routeConstants).filter(([key]) => key !== "_EN"))
      : routeConstants["_EN"];

  const pathParts = path.split(RootContext);

  let extractedPart;
  if (Object.values(prevLangRoutes).some((route) => route.split("/:")[0] === `/${pathParts[1]}`)) {
    extractedPart = `/${pathParts[1]}`;
  } else {
    extractedPart = `/${pathParts[2]}`;
  }

  const matchingPathKey = Object.entries(prevLangRoutes).find(([key, route]) =>
    route.includes(extractedPart)
  )[0];

  let finalRoute;
  if (newLanguage === "pl") {
    finalRoute = path
      .replace(`/${prevLanguage}`, "/")
      .replace(extractedPart, newLangRoutes[matchingPathKey].split("/")[1]);
  } else if (prevLanguage === "pl") {
    finalRoute = `/${newLanguage}${path.replace(
      extractedPart,
      `/${newLangRoutes[matchingPathKey].split("/")[1]}`
    )}`;
  } else {
    finalRoute = path.replace(`/${prevLanguage}`, `/${newLanguage}`);
  }

  return finalRoute;
};

const routeService = {
  getRoute,
  getRouteAfterLangChange
};

export default routeService;
