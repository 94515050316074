import { createSlice } from "@reduxjs/toolkit";
import { toAdditionalOptions } from "../../pages/forRestaurantsPage/forRestaurantsPageForm/ContractPdfData";

const initial = () => {
  return {
    planPrice: {
      price: 75,
      comment: "" ?? null
    },
    commissionPrice: {
      price: 0,
      comment: "" ?? null
    },
    optionPrices: {
      Prepayments: {
        price: 50,
        comment: "" ?? null
      },
      Ivr: {
        price: 250,
        comment: "" ?? null
      },
      SmsPackage: {
        price: 80,
        comment: "" ?? null
      },
      Facebook: {
        price: 50,
        comment: "" ?? null
      },
      TripAdvisor: {
        price: 5,
        comment: "" ?? null
      },
      Michelin: {
        price: 9,
        comment: "" ?? null
      }
    }
  };
};


const initialState = {
  plansData: {
    Light: {
      type: "Light",
      name: "forRestaurantsPage.offers.offerItem.1.header",
      desc: "forRestaurantsPage.offers.offerItem.1.text"
    },
    Advanced: {
      type: "Advanced",
      name: "forRestaurantsPage.offers.offerItem.2.header",
      desc: "forRestaurantsPage.offers.offerItem.2.text"
    },
    AdvancedPro: {
      type: "AdvancedPro",
      name: "forRestaurantsPage.offers.offerItem.3.header",
      subName: "forRestaurantsPage.offers.offerItem.3.header.orange",
      desc: "forRestaurantsPage.offers.offerItem.3.text"
    }
  },
  perMonthPrices: {
    Yearly: {
      Light: {
        Regular: initial(),
        Discounted: initial()
      },
      Advanced: {
        Regular: initial(),
        Discounted: initial()
      },
      AdvancedPro: {
        Regular: initial(),
        Discounted: initial()
      }
    },
    Monthly: {
      Light: {
        Regular: initial(),
        Discounted: initial()
      },
      Advanced: {
        Regular: initial(),
        Discounted: initial()
      },
      AdvancedPro: {
        Regular: initial(),
        Discounted: initial()
      }
    }
  },
  offerChosen: {
    type: "",
    name: "",
    desc: "",
    subName: "",
    price: 0,
    billingPeriod: "",
    additionalOptions: {
      ivr: false,
      prepayments: false,
      smsPackage: false,
      facebook: false,
      tripAdvisor: false,
      michelin: false
    },
    isFirstRender: true,
    discountCode: ""
  }
};


const toAdditionalOptionsFromState = (state) => {
  return toAdditionalOptions(state.offerChosen)
};



const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    getConfigData(state, action) {
      state.perMonthPrices = action.payload;
    },
    setInitialOfferChosenData(state, action) {
      state.offerChosen.type = action.payload.type;
      state.offerChosen.name = action.payload.name;
      state.offerChosen.subName = action.payload.subName;
      state.offerChosen.desc = action.payload.desc;
      state.offerChosen.price = action.payload.price;
      state.offerChosen.billingPeriod = "Monthly";
    },
    light(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.Light.type,
        name: state.plansData.Light.name,
        desc: state.plansData.Light.desc,
        subName: "",
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: toAdditionalOptionsFromState(state)
      };
    },
    advanced(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.Advanced.type,
        name: state.plansData.Advanced.name,
        desc: state.plansData.Advanced.desc,
        subName: "",
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: toAdditionalOptionsFromState(state)
      };
    },
    advancedPro(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.AdvancedPro.type,
        name: state.plansData.AdvancedPro.name,
        desc: state.plansData.AdvancedPro.desc,
        subName: state.plansData.AdvancedPro.subName,
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: toAdditionalOptionsFromState(state)
      };
    },
    prepaymentsOptionChange(state) {
      state.offerChosen.additionalOptions.prepayments =
        !state.offerChosen.additionalOptions.prepayments;
    },
    ivrOptionChange(state) {
      state.offerChosen.additionalOptions.ivr =
        !state.offerChosen.additionalOptions.ivr;
    },
    smsPackageOptionChange(state) {
      state.offerChosen.additionalOptions.smsPackage =
        !state.offerChosen.additionalOptions.smsPackage;
    },
    facebookOptionChange(state) {
      state.offerChosen.additionalOptions.facebook =
        !state.offerChosen.additionalOptions.facebook;
    },
    tripAdvisorOptionChange(state) {
      state.offerChosen.additionalOptions.tripAdvisor =
        !state.offerChosen.additionalOptions.tripAdvisor;
    },
    michelinOptionChange(state) {
      state.offerChosen.additionalOptions.michelin =
        !state.offerChosen.additionalOptions.michelin;
    },
    addPrice(state, action) {
      state.offerChosen.price = state.offerChosen.price + action.payload;
    },
    billingPeriod(state, action) {
      state.offerChosen.billingPeriod = action.payload;
    },
    setIsFirstRender(state, action) {
      state.offerChosen.isFirstRender = action.payload;
    },
    updateCode(state, action) {
      state.offerChosen.discountCode = action.payload;
    }
  }
});

export const offersActions = offerSlice.actions;
export default offerSlice.reducer;
