export const ContractPdfData = (values, formData, offerChosen) => {
  return {
    companyInfo: toCompanyInfo(values),
    restaurantInfo: toRestaurantInfo(formData),
    subscription: toSubscription(formData, offerChosen),
    promotionCode: offerChosen.discountCode || null
  };
}

export const FormObjectData = (formData, offerChosen) =>  ContractPdfData(formData.companyInfo,formData, offerChosen);

const toCompanyInfo =  values => {
  return {
    name: values.name,
    address: {
      buildingNumber: values.address_buildingNumber,
      city: values.address_city,
      postalCode: values.address_postalCode,
      street: values.address_street,
      localNumber: values.address_localNumber
    },
    contactEmail: values.contactEmail,
    invoiceEmail: values.invoiceEmail,
    phoneNumber: values.phoneNumber,
    vatIdNumber: values.vatId,
    krsNumber: values.krs,
    regonNumber: values.regon,
    webUrl: values.webUrl,
    contactPerson:  toContactPerson(values, )
  }
}

const toContactPerson = (data) => {
  return {
    id: 0,
    firstName: data.contactPerson_firstName,
    lastName: data.contactPerson_lastName,
    email: data.contactPerson_email,
    phoneNumber: data.contactPerson_phoneNumber,
    role: data.contactPerson_role ?? "Owner"
  }
}


const toRestaurantInfo = formData => {
  return {
    name: formData.restaurantInfo.name,
    address: {
      buildingNumber: formData.restaurantInfo.address_buildingNumber,
      city: formData.restaurantInfo.address_city,
      postalCode: formData.restaurantInfo.address_postalCode,
      street: formData.restaurantInfo.address_street,
      localNumber: formData.restaurantInfo.address_localNumber
    },
    email: formData.restaurantInfo.email,
    phoneNumber: formData.restaurantInfo.phoneNumber,
    webUrl: formData.restaurantInfo.webUrl,
    menuUrl: formData.restaurantInfo.menuUrl,
    contactPerson: toContactPerson(formData.restaurantInfo)
  }
}

const toSubscription = (formData, offerChosen) => {
  return {
    id: 0,
    plan: formData.subscriptionPlan,
    billingPeriod: offerChosen.billingPeriod,
    options: toAdditionalOptions(offerChosen)
  };
};

export const toAdditionalOptions = (offerChosen) => {
  return {
    ivr: offerChosen.additionalOptions.ivr,
    prepayments: offerChosen.additionalOptions.prepayments,
    smsPackage: offerChosen.additionalOptions.smsPackage,
    facebook: offerChosen.additionalOptions.facebook,
    tripAdvisor: offerChosen.additionalOptions.tripAdvisor,
    michelin: offerChosen.additionalOptions.michelin
  };
};
