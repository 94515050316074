import React from "react";
import { requestService } from "./services";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("ErrorBoundary", error)
    console.log("ErrorBoundaryInfo",errorInfo)
    requestService.logError(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      window.location.reload()
    }
    return this.props.children;
  }
}