import moment from "moment/moment";

export const shouldSendEmail = (phoneNumberWithCC: string) => !phoneNumberWithCC.startsWith("+48");

export const DayFormat = "YYYY-MM-DD"

export const TimeFormat = "HH:mm"

export const DateTimeFormat = `${DayFormat}T${TimeFormat}`


export const RootContext = "/"



export const isValidDateTime = dateTime => dateTime && moment(dateTime, DateTimeFormat, true).isValid()

export const isValidTime = time => time && moment(time, TimeFormat, true).isValid()