import moment from "moment";
import classnames from "classnames";
import CheckBox from "../../components/checkBox/CheckBox";
import React from "react";

export const parseDate = (date, firstAvailableTime) => {
  return date &&
    moment(date.date ?? date).isSameOrAfter(moment())
    ? date.date ?? date
    : firstAvailableTime["date"] ?? firstAvailableTime;
};
export const activePromotion = (restaurantPromotions, promotionValue) => {
  return restaurantPromotions?.find(
    (p) => p.id.value === Number(promotionValue || -1)
  );
};

export const eventCheckbox = (
  errors,
  touched,
  t,
  configuration,
  setFieldValue,
  currentValue
) => {
  return (
    configuration?.eventAgreementUrl?.value && (
      <div className="formItem">
        <CheckBox
          id="eventAgreement"
          className={classnames({
            "checkboxContainer--error":
              errors.eventAgreement && touched.eventAgreement,
            "checkboxContainer--success":
              !errors.eventAgreement && touched.eventAgreement,
          })}
          checked={currentValue}
          onChange={(newValue) => {
            const isChecked = newValue.currentTarget.checked;
            setFieldValue("eventAgreement", isChecked);
          }}
        >
          {t("agreement.eventAgreement")}{" "}
          {configuration?.eventAgreementUrl?.value && (
            <a
              onClick={(event) => {
                event.stopPropagation();
              }}
              href={configuration.eventAgreementUrl.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("registrationPage.input.label.eventAgreementUrl.extension")}
            </a>
          )}
          .
        </CheckBox>
      </div>
    )
  );
};

export const eventAgreementCheckbox = (
  errors,
  touched,
  t,
  promotion,
  setFieldValue,
  currentValue
) => {
  return (
    promotion &&
    promotion.agreement && (
      <div className="formItem">
        <CheckBox
          id="promotionReadWithdrawal"
          className={classnames({
            singleLine: false,
            "checkboxContainer--error":
              errors.promotionReadWithdrawal && touched.promotionReadWithdrawal,
            "checkboxContainer--success":
              !errors.promotionReadWithdrawal &&
              touched.promotionReadWithdrawal,
          })}
          checked={currentValue}
          onChange={(newValue) => {
            const isChecked = newValue.currentTarget.checked;
            setFieldValue("promotionReadWithdrawal", isChecked);
          }}
          // checked={values.promotionReadWithdrawal}
        >
          {t("promotion.readWithdrawal")}
          {" ("}
          <a
            onClick={(event) => {
              event.stopPropagation();
            }}
            href={promotion.agreement}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              "registrationPage.input.label.promotionReadWithdrawal.extension"
            )}
          </a>
          ).
        </CheckBox>
      </div>
    )
  );
};
