import React from "react";
import { routeConstants } from "../constants";
import IndexPage from "../pages/indexPage/IndexPage";
import LogoutPage from "../pages/logoutPage/LogoutPage";
import NotFoundPage from "../pages/notFoundPage/NotFoundPage";
import {
  languageConstants,
} from "constants/languageConstants";
import { RootContext } from "../utils/utils";

//const IndexPage = React.lazy(() => import('../pages/indexPage/IndexPage'));
const AccountPage = React.lazy(() =>
  import("../pages/accountPage/AccountPage")
);
const ReservationHistoryPage = React.lazy(() =>
  import("../pages/reservationHistoryPage/ReservationHistoryPage")
);

const PrivacyPolicyPage = React.lazy(() =>
  import("../pages/privacyPolicyPage/PrivacyPolicyPage")
);
const CookiesPolicyPage = React.lazy(() =>
  import("../pages/cookiesPolicyPage/CookiesPolicyPage")
);
const ForRestaurantsPage = React.lazy(() =>
  import("../pages/forRestaurantsPage/ForRestaurantsPage")
);
const ForRestaurantsPageAllOffers = React.lazy(() =>
  import(
    "../pages/forRestaurantsPage/forRestaurantsPageAllOffers/ForRestaurantsPageAllOffers"
  )
);
const ForRestaurantsPageForm = React.lazy(() =>
  import(
    "../pages/forRestaurantsPage/forRestaurantsPageForm/ForRestaurantsPageForm"
  )
);
const ForRestaurantsPageSuccess = React.lazy(() =>
  import("../pages/forRestaurantsPage/ForRestaurantsPageSuccess")
);
const RestaurantCovid = React.lazy(() =>
  import("../pages/forRestaurantsPage/RestaurantCovid")
);
const ClientCovid = React.lazy(() =>
  import("../pages/forRestaurantsPage/ClientCovid")
);
const Christmas = React.lazy(() =>
  import("../pages/forRestaurantsPage/Christmas")
);
const ForGuestPage = React.lazy(() =>
  import("../pages/forGuestPage/ForGuestPage")
);
const HowItWorksPage = React.lazy(() =>
  import("../pages/howItWorksPage/HowItWorksPage")
);
const PriceListPage = React.lazy(() =>
  import("../pages/priceListPage/PriceListPage")
);
const ContactPage = React.lazy(() =>
  import("../pages/contactPage/ContactPage")
);
const DocumentsPage = React.lazy(() =>
  import("../pages/documentsPage/DocumentsPage")
);
const AboutPage = React.lazy(() => import("../pages/aboutPage/AboutPage"));
const RestaurantProfilePage = React.lazy(() =>
  import("../pages/restaurantProfilePage/RestaurantProfilePage")
);
const LoginPage = React.lazy(() => import("../pages/loginPage/LoginPage"));
const RegistrationPage = React.lazy(() =>
  import("../pages/registrationPage/RegistrationPage")
);
const SearchPage = React.lazy(() => import("../pages/searchPage/SearchPage"));
const SearchMapPage = React.lazy(() =>
  import("../pages/searchMapPage/SearchMapPage")
);
const ResetPasswordPage = React.lazy(() =>
  import("../pages/resetPasswordPage/ResetPasswordPage")
);
const BlogPage = React.lazy(() => import("../pages/blogPage/BlogPage"));
const ArticlePage = React.lazy(() => import("../pages/blogPage/ArticlePage"));
const ReservationPage = React.lazy(() =>
  import("../pages/reservationPage/ReservationPage")
);
const SurveyPage = React.lazy(() => import("../pages/surveyPage/SurveyPage"));

export const routes = languageConstants.flatMap((lang) => {
  if (lang === "pl") {
    return [
      {
        uuid: "index",
        path: RootContext,
        exact: true,
        component: IndexPage,
      },
      {
        uuid: "reset_password",
        path: routeConstants["RESET_PASSWORD"],
        component: ResetPasswordPage,
      },
      {
        uuid: "account",
        path: routeConstants["YOUR_ACCOUNT"],
        component: AccountPage,
      },
      {
        uuid: "reservation_history",
        path: routeConstants["RESERVATION_HISTORY"],
        component: ReservationHistoryPage,
      },
      {
        uuid: "privacy_policy",
        path: routeConstants["PRIVACY_POLICY"],
        component: PrivacyPolicyPage,
      },
      {
        uuid: "cookies_policy",
        path: routeConstants["COOKIES_POLICY"],
        component: CookiesPolicyPage,
      },
      {
        uuid: "for_restaurants",
        path: routeConstants["FOR_RESTAURANTS"],
        component: ForRestaurantsPage,
      },
      {
        uuid: "for_restaurants_alloffers",
        path: routeConstants["FOR_RESTAURANTS_ALLOFFERS"],
        component: ForRestaurantsPageAllOffers,
      },
      {
        uuid: "for_restaurants_form",
        path: routeConstants["FOR_RESTAURANTS_FORM"],
        component: ForRestaurantsPageForm,
      },
      {
        uuid: "for_restaurants_success",
        path: routeConstants["FOR_RESTAURANTS_SUCCESS"],
        component: ForRestaurantsPageSuccess,
      },
      {
        uuid: "blog",
        path: routeConstants["BLOG"],
        component: BlogPage,
      },
      {
        uuid: "chistmas",
        path: routeConstants["CHRISTMAS"],
        component: Christmas,
      },
      {
        uuid: "covid",
        path: routeConstants["COVID"],
        component: RestaurantCovid,
      },
      {
        uuid: "covid-client",
        path: routeConstants["COVID_CLIENT"],
        component: ClientCovid,
      },
      {
        uuid: "article_blog",
        path: routeConstants["ARTICLE_BLOG"],
        component: ArticlePage,
      },
      {
        uuid: "for_gursts",
        path: routeConstants["FOR_GUESTS"],
        component: ForGuestPage,
      },
      {
        uuid: "how_it_works",
        path: routeConstants["HOW_IT_WORKS"],
        component: HowItWorksPage,
      },
      {
        uuid: "price_list",
        path: routeConstants["PRICE_LIST"],
        component: PriceListPage,
      },
      {
        uuid: "contact",
        path: routeConstants["CONTACT"],
        component: ContactPage,
      },
      {
        uuid: "documents",
        path: routeConstants["DOCUMENTS"],
        component: DocumentsPage,
      },
      {
        uuid: "about",
        path: routeConstants["ABOUT"],
        component: AboutPage,
      },
      {
        uuid: "restaurant_profile",
        path: routeConstants["RESTAURANT_PROFILE"],
        component: RestaurantProfilePage,
      },
      {
        uuid: "searchFilters",
        path: routeConstants["SEARCH_FILTERS"],
        component: SearchPage,
      },
      {
        uuid: "search",
        path: routeConstants["SEARCH"],
        component: SearchPage,
        exact: true,
      },
      {
        uuid: "searchMapFilters",
        path: routeConstants["SEARCH_MAP_FILTERS"],
        component: SearchMapPage,
      },
      {
        uuid: "searchMap",
        path: routeConstants["SEARCH_MAP"],
        component: SearchMapPage,
        exact: true,
      },
      {
        uuid: "login",
        path: routeConstants["LOGIN"],
        component: LoginPage,
      },
      {
        uuid: "logout",
        path: routeConstants["LOGOUT"],
        component: LogoutPage,
      },
      {
        uuid: "registration",
        path: routeConstants["REGISTRATION"],
        component: RegistrationPage,
      },
      {
        uuid: "reservation",
        path: routeConstants["RESERVATION"],
        component: ReservationPage,
      },
      {
        uuid: "survey",
        path: routeConstants["SURVEY"],
        component: SurveyPage,
      },
    ];
  } else {
    return [
      {
        uuid: `index_${lang}`,
        path: `/${lang}`,
        exact: true,
        component: IndexPage,
      },
      {
        uuid: `reset_password_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["RESET_PASSWORD"]}`,
        component: ResetPasswordPage,
      },
      {
        uuid: `account_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["YOUR_ACCOUNT"]}`,
        component: AccountPage,
      },
      {
        uuid: `reservation_history_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["RESERVATION_HISTORY"]}`,
        component: ReservationHistoryPage,
      },
      {
        uuid: `privacy_policy_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["PRIVACY_POLICY"]}`,
        component: PrivacyPolicyPage,
      },
      {
        uuid: `cookies_policy_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["COOKIES_POLICY"]}`,
        component: CookiesPolicyPage,
      },
      {
        uuid: `for_restaurants_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["FOR_RESTAURANTS"]}`,
        component: ForRestaurantsPage,
      },
      {
        uuid: `for_restaurants_alloffers_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["FOR_RESTAURANTS_ALLOFFERS"]}`,
        component: ForRestaurantsPageAllOffers,
      },
      {
        uuid: `for_restaurants_form_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["FOR_RESTAURANTS_FORM"]}`,
        component: ForRestaurantsPageForm,
      },
      {
        uuid: `for_restaurants_success_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["FOR_RESTAURANTS_SUCCESS"]}`,
        component: ForRestaurantsPageSuccess,
      },
      {
        uuid: "blog",
        path: `/${lang}${routeConstants["_EN"]["BLOG"]}`,
        component: BlogPage,
      },
      {
        uuid: `chistmas_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["CHRISTMAS"]}`,
        component: Christmas,
      },
      {
        uuid: `covid_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["COVID"]}`,
        component: RestaurantCovid,
      },
      {
        uuid: `covid-client_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["COVID_CLIENT"]}`,
        component: ClientCovid,
      },
      {
        uuid: `article_blog_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["ARTICLE_BLOG"]}`,
        component: ArticlePage,
      },
      {
        uuid: `for_gursts_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["FOR_GUESTS"]}`,
        component: ForGuestPage,
      },
      {
        uuid: `how_it_works_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["HOW_IT_WORKS"]}`,
        component: HowItWorksPage,
      },
      {
        uuid: `price_list_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["PRICE_LIST"]}`,
        component: PriceListPage,
      },
      {
        uuid: `contact_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["CONTACT"]}`,
        component: ContactPage,
      },
      {
        uuid: `documents_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["DOCUMENTS"]}`,
        component: DocumentsPage,
      },
      {
        uuid: `about_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["ABOUT"]}`,
        component: AboutPage,
      },
      {
        uuid: `restaurant_profile_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["RESTAURANT_PROFILE"]}`,
        component: RestaurantProfilePage,
      },
      {
        uuid: `searchFilters_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["SEARCH_FILTERS"]}`,
        component: SearchPage,
      },
      {
        uuid: `search_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["SEARCH"]}`,
        component: SearchPage,
        exact: true,
      },
      {
        uuid: `searchMapFilters_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["SEARCH_MAP_FILTERS"]}`,
        component: SearchMapPage,
      },
      {
        uuid: `searchMap_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["SEARCH_MAP"]}`,
        component: SearchMapPage,
        exact: true,
      },
      {
        uuid: `login_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["LOGIN"]}`,
        component: LoginPage,
      },
      {
        uuid: `logout_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["LOGOUT"]}`,
        component: LogoutPage,
      },
      {
        uuid: `registration_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["REGISTRATION"]}`,
        component: RegistrationPage,
      },
      {
        uuid: `reservation_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["RESERVATION"]}`,
        component: ReservationPage,
      },
      {
        uuid: `survey_${lang}`,
        path: `/${lang}${routeConstants["_EN"]["SURVEY"]}`,
        component: SurveyPage,
      },
    ];
  }
});

routes.push({
  uuid: "not_found",
  component: NotFoundPage,
});
